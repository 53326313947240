// Import the default Big Calendar styles
@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // Only if using drag-and-drop

// Define custom styling variables for your calendar
$calendar-bg: #ffffff;
$calendar-event-bg: #164A1B; // Matches your dark green
$calendar-event-color: #ffffff;
$calendar-event-hover-bg: #FF6F3C; // Matches your accent color

// Customize the calendar background, font, and event styling
.rbc-calendar {
  background-color: $calendar-bg;
  font-family: Arial, sans-serif;
  font-size: 14px;
  //border: 1px solid $calendar-border-color;
}

.rbc-event {
  background-color: $calendar-event-bg;
  color: $calendar-event-color;

  &:hover {
    background-color: $calendar-event-hover-bg;
  }
}

.rbc-toolbar {
  background-color: $calendar-bg;
  //border-bottom: 1px solid $calendar-border-color;
}

.rbc-toolbar-label {
  font-weight: bold;
  //color: $calendar-border-color;
}

.rbc-header {
  background-color: $calendar-bg;
  //color: $calendar-border-color;
  font-weight: bold;
  //border-bottom: 1px solid $calendar-border-color;
}

.rbc-date-cell {
  &.rbc-off-range {
    color: #ccc; // Grey for off-range dates
  }
}






// Additional styling for dark mode (optional)
.rbc-calendar.dark-mode {
  background-color: #3e3e42;
  color: #ffffff;

  .rbc-event {
    background-color: #488A4F;
    color: #ffffff;
  }

  .rbc-header,
  .rbc-toolbar {
    color: #ffffff;
  }
}

