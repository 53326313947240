@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Required for 3D flip effect */
.backface-hidden {
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.rotate-y-180 {
  transform: rotateY(180deg);
}

/* Container needs to define perspective */
.perspective {
  perspective: 1000px; /* Creates the 3D space effect */
}

.preserve-3d {
  transform-style: preserve-3d;
}

/* Add this to your CSS file or a style block */
/*.grid {*/
/*  display: grid;*/
/*  grid-template-columns: repeat(3, 1fr); !* Always 3 columns *!*/
/*  gap: 16px; !* Adjust gap between grid items *!*/
/*  grid-auto-flow: dense; !* Allows the grid to fill gaps automatically *!*/
/*}*/


.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.border-gradient {
  border-image-source: linear-gradient(90deg, #ff6f3c, #488a4f, #164a1b, #ff6f3c);
  border-image-slice: 1;
  animation: gradient-animation 3s infinite linear;
}

@keyframes gradient-animation {
  0% { border-image-source: linear-gradient(90deg, #ff6f3c, #488a4f, #164a1b, #ff6f3c); }
  100% { border-image-source: linear-gradient(90deg, #164a1b, #ff6f3c, #488a4f, #164a1b); }
}

.wave {
  animation: wave-animation 1.5s infinite linear;
}
@keyframes wave-animation {
  0%, 100% { border-width: 4px; }
  50% { border-width: 8px; }
}

.flicker {
  animation: flicker-animation 1.5s infinite;
}
@keyframes flicker-animation {
  0%, 100% { border-color: #488a4f; }
  50% { border-color: #ff6f3c; }
}

.border-moving {
  border-color: transparent;
  background-image: linear-gradient(white, white),
  linear-gradient(90deg, #ff6f3c, #488a4f, #164a1b);
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: dash-animation 1s linear infinite;
}
@keyframes dash-animation {
  from { background-position: 0 0; }
  to { background-position: 100% 100%; }
}


@property --border-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: '<angle>';
}

@layer utilities {
  .animate-rubberBand-delay {
    animation: rubberBand 1s;
    animation-delay: 0.5s; /* Adjust delay as needed */
    animation-fill-mode: both; /* Ensures the animation starts after the delay */
  }
}

@keyframes timeout {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

.animate-timeout {
  animation: timeout linear forwards;
}

@keyframes progress {
  0% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.group-hover .animate-scroll {
  display: inline-block;
  animation: scrollText 5s linear infinite;
}